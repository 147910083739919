import * as React from "react"
import { graphql } from "gatsby"
import { StoryblokComponent, useStoryblokState } from "gatsby-source-storyblok"
import Layout from "../global/layout"
import HTMLHead from '../global/HTMLHead'
import HTMLFooter from '../global/HTMLFooter'
import { getExcerpt } from "../global/helpers"

const StoryblokEntry = ({ data }) => {
    let story = data.storyblokEntry
    story = useStoryblokState(story)

    let pageInfo = {
      name : story.name,
      full_slug : story.full_slug,
      uuid : story.uuid,
      id : story.id,
      internalId : story.internalId,
      slug : story.slug
    } 

    const components = story.content.sections.map(blok => (<StoryblokComponent blok={blok} key={blok._uid} />))
    return (
        <Layout pageInfo={pageInfo}>
        <>
            {components}
        </>
        <HTMLFooter data={data}></HTMLFooter>
        </Layout>
    )
}

export const Head = ({ data }) => {
  const metaTitle = data.storyblokEntry.content.metatags?.title || `${data.storyblokEntry.name} | ${data.site.siteMetadata.title}`
  const metaDescription = data.storyblokEntry.content.metatags?.description || getExcerpt(data.storyblokEntry) || data.site.siteMetadata.description
    
  return(
    <>
      <title>{metaTitle + ' | Zignal Labs'}</title>
      <meta name="description" content={metaDescription} />
      <meta name="google-site-verification" content="7efGbzQVU5GesMwBmb4SOs6oSPtUtkfCuOnHnnYdAnM" />
      <HTMLHead data={data}></HTMLHead>
    </>
  )
}

export default StoryblokEntry
 
export const query = graphql`
  query{
    storyblokEntry(full_slug: { eq: "home" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    },
    site {
      siteMetadata {
        title
        description
        privateCollections
      }
    }
  }
`
